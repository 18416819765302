import PropTypes from 'prop-types';
import React, { useState } from 'react';
import FaceImage from 'images/icons/Man@2x.svg'
import LiveImage from 'images/icons/Computer@2x.svg'
import SelfImage from 'images/icons/Concierge@2x.svg'
import TeamImage from 'images/icons/Team@2x.svg'
import SpeakerImage from 'images/icons/speaker-icon.png'

const LearningTile = (props) => {
  const { course_path, team_learning_path, guest_speaker_path } = props;

  return (
    <div className="heading text-center">
      <h2>Learning modes for different needs</h2>
      <div className="row learn-mods">
        <div className="col-sm-6 col-md-4 mobile-space">
          <div>
            <div className="icon">
              <img src={FaceImage} className="w-50" />
            </div>
            <h3>Face to face</h3>
            <p>A rolling schedule of classes across Australia means there’s always a net101 course around when you need it.</p>
          </div>
          <a className="btn btn-warning" href={course_path + "?formet=Face-to-face"}>Learn more</a>
        </div>
        <div className="col-sm-6 col-md-4 mobile-space">
          <div>
            <div className="icon">
              <img src={TeamImage} className="w-50" />
            </div>
            <h3>Team learning</h3>
            <p>When it's time to get the whole team up to speed. Program durations include half-day, 2 day or a rolling series.</p>
          </div>
          <a className="btn btn-warning" href={team_learning_path}>Learn more</a>
        </div>
        <div className="col-sm-6 col-md-4">
          <div>
            <div className="icon">
              <img src={SpeakerImage} className="w-50" />
            </div>
            <h3>Guest speaker</h3>
            <p>Get ready to be inspired and informed on the incredible impact of AI and generative tools on various industries.</p>
          </div>
          <a className="btn btn-warning" href={guest_speaker_path}>Learn more</a>
        </div>
      </div>
    </div>
  );
};

export { LearningTile };
