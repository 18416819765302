import ReactOnRails from 'react-on-rails';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import { HelloWorld } from '../components/HelloWorld';
import { Booking } from '../components/Booking';
import { LearningTile } from '../components/LearningTile';
import { CourseSearch } from '../components/CourseSearch';
import { MainSearch } from '../components/MainSearch';
import { Search } from '../components/Search';
import { CourseSideComponent } from '../components/CourseSideComponent';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import App from '../routes/App';

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  App,
  HelloWorld,
  Booking,
  LearningTile,
  CourseSearch,
  MainSearch,
  CourseSideComponent,
});


if (typeof document !== 'undefined') {
  // Do your document thing here
	ReactDOM.render(<App />, document.getElementById('root'));
} else {
  console.log("We're on server")
}