import PropTypes from 'prop-types';
import React from 'react';
import axios from 'axios';

const CourseSearch = (props) => {
  const { topics, locations, months, levels, formets } = props;
  const filter = (param) => {
    var filters = {};
    var selectedVal = param.split('=')[1];
    var selectedKey = param.split('=')[0];
    let types = ['topic', 'formet', 'location', 'month', 'level'];
    let button_name = new Map([
      ['topic', 'Topics'],
      ['location', 'Locations'],
      ['month', 'Month'],
      ['level', 'Level'],
      ['formet', 'Formats']
    ]);

    if (selectedVal == "all") {
      var p = document.getElementById(selectedKey).parentElement;
      $("#" + p.id).find('input[type=checkbox]').each(function () {
        this.checked = !(document.getElementById(selectedKey + '=all').checked);
      });
    }

    types.forEach(function (type) {
      var selectedValues = [];
      $("input." + type + "-option:checked").each(function(){
        selectedValues.push($(this).val().split("=")[1]);
      });
      filters[type] = selectedValues.join(",");
    });

    var label = button_name.get(selectedKey);
    var allOptions = $("input." + selectedKey + "-option")
    var checkedOptions = $("input." + selectedKey + "-option:checked")
    if (checkedOptions.length == 1) {
      label = checkedOptions.val().split("=")[1].split('_').join(' ');
    } else if (checkedOptions.length > 1 && allOptions.length != checkedOptions.length) {
      label = checkedOptions[0].value.split("=")[1].split('_').join(' ') + " + " + (checkedOptions.length - 1).toString();
    }
    $("#"+selectedKey).html(label + " <i class='fa fa-angle-up'></i>");

    var search_params = "";

    for (const [key, value] of Object.entries(filters)) {
      if(value.length > 0) {
        if(search_params.length > 0) {
          search_params += "&"
        }
        search_params += key + "=" + value
      }
    }

    history.pushState({},"", `/courses?${search_params}`);
    $.ajax({
      url: `/courses?${search_params}`,
      dataType: 'script',
      type: 'GET',
      cache: false,
      success: function(data) {
        console.log({ data });
      }.bind(this),
      error: function(xhr, status, err) {
        console.log({ err });
      }
    });
    var parentdiv = document.getElementById(selectedKey).parentElement;
    if( $('#'+parentdiv.id+' .scrollable-dropdown :checkbox').length <= $('#'+parentdiv.id+' .scrollable-dropdown :checkbox:checked').length)
    {
      document.getElementById(selectedKey+'=all').checked = true;
    }
    else{
      document.getElementById(selectedKey+'=all').checked = false;
    }
  };

  return (
    <div class="new-filter-section">
      <div class="btn-group" id="topics">
        <button type="button" class="btn btn-default dropdown-toggle" aria-haspopup="true" aria-expanded="false" id="topic">
          Topics <i class="fa fa-angle-down"></i>
        </button>
        <div class="dropdown-menu">
          <div class="head">
            <strong>Topics</strong>
            <a href="" class="filter-cross"><i class="fa fa-times"></i></a>
          </div>
          <div class="dropdown-body">
            <div class="holder">
              <span>Select all topics</span>
              <label class="custom-switch"> 
                <input type="checkbox" id="topic=all" value="topic=all" onChange={e => filter(e.target.value)}></input>
                <span class="switch-slider switch-round"></span>
              </label>
            </div>
            <div class="scrollable-dropdown">
              {topics.map(topic => {
                return(
                  <label class="custom-checkbox">{topic}
                    <input type="checkbox" id= {`topic=${topic.replace(/ /g,"_")}`} value={`topic=${topic.replace(/ /g,"_")}`} onChange={e => filter(e.target.value)} class="topic-option"></input>
                    <span class="checkmark"></span>
                  </label>
                );
              })}
          </div>
          </div>
        </div>
      </div>
      <div class="btn-group" id="formets">
        <button type="button" class="btn btn-default dropdown-toggle" aria-haspopup="true" aria-expanded="false" id="formet">
          Formats <i class="fa fa-angle-down"></i>
        </button>
        <div class="dropdown-menu">
          <div class="head">
            <strong>Formats</strong>
            <a href="" class="filter-cross"><i class="fa fa-times"></i></a>
          </div>
          <div class="dropdown-body">
            <div class="holder">
              <span>Select all formats</span>
              <label class="custom-switch"> 
                <input type="checkbox" id="formet=all" value="formet=all" onChange={e => filter(e.target.value)}></input>
                <span class="switch-slider switch-round"></span>
              </label>
            </div>
            <div class="scrollable-dropdown">
              {formets.map(formet => {
                return(
                  <label class="custom-checkbox">{formet}
                    <input type="checkbox" id={`formet=${formet.replace(/ /g,"_")}`} value={`formet=${formet.replace(/ /g,"_")}`} onChange={e => filter(e.target.value)} class="formet-option"></input>
                    <span class="checkmark"></span>
                  </label>
                );
              })}
          </div>
          </div>
        </div>
      </div>
      <div class="btn-group" id="locations">
        <button type="button" class="btn btn-default dropdown-toggle" aria-haspopup="true" aria-expanded="false" id="location">
          Locations <i class="fa fa-angle-down"></i>
        </button>
        <div class="dropdown-menu">
          <div class="head">
            <strong>Locations</strong>
            <a href="" class="filter-cross"><i class="fa fa-times"></i></a>
          </div>
          <div class="dropdown-body">
            <div class="holder">
              <span>Select all locations</span>
              <label class="custom-switch"> 
                <input type="checkbox" id="location=all" value="location=all" onChange={e => filter(e.target.value)}></input>
                <span class="switch-slider switch-round"></span>
              </label>
            </div>
            <div class="scrollable-dropdown">
              {locations.map(location => {
                return(
                  <label class="custom-checkbox">{location}
                    <input type="checkbox" id={`location=${location.replace(/ /g,"_")}`} value={`location=${location.replace(/ /g,"_")}`} onChange={e => filter(e.target.value)} class="location-option"></input>
                    <span class="checkmark"></span>
                  </label>
                );
              })}
          </div>
          </div>
        </div>
      </div>
      <div class="btn-group" id="months">
        <button type="button" class="btn btn-default dropdown-toggle" aria-haspopup="true" aria-expanded="false" id="month">
          Month <i class="fa fa-angle-down"></i>
        </button>
        <div class="dropdown-menu">
          <div class="head">
            <strong>Months</strong>
            <a href="" class="filter-cross"><i class="fa fa-times"></i></a>
          </div>
          <div class="dropdown-body">
            <div class="holder">
              <span>Select all months</span>
              <label class="custom-switch"> 
                <input type="checkbox" id="month=all" value="month=all" onChange={e => filter(e.target.value)}></input>
                <span class="switch-slider switch-round"></span>
              </label>
            </div>
            <div class="scrollable-dropdown">
              {Object.keys(months).map(month => {
                return(
                  <label class="custom-checkbox">{month}
                    <input type="checkbox" id= {`month=${month.replace(/ /g,"_")}`} value={`month=${month.replace(/ /g,"_")}`} onChange={e => filter(e.target.value)} class="month-option"></input>
                    <span class="checkmark"></span>
                  </label>
                );
              })}
          </div>
          </div>
        </div>
      </div>
      <div class="btn-group" id="levels">
        <button type="button" class="btn btn-default dropdown-toggle" aria-haspopup="true" aria-expanded="false" id="level">
          Level <i class="fa fa-angle-down"></i>
        </button>
        <div class="dropdown-menu">
          <div class="head">
            <strong>Levels</strong>
            <a href="" class="filter-cross"><i class="fa fa-times"></i></a>
          </div>
          <div class="dropdown-body">
            <div class="holder">
              <span>Select all levels</span>
              <label class="custom-switch"> 
                <input type="checkbox" id="level=all" value="level=all" onChange={e => filter(e.target.value)}></input>
                <span class="switch-slider switch-round"></span>
              </label>
            </div>
            <div class="scrollable-dropdown">
              {levels.map(level => {
                return(
                  <label class="custom-checkbox">{level}
                    <input type="checkbox" id={`level=${level.replace(/ /g,"_")}`} value={`level=${level.replace(/ /g,"_")}`} onChange={e => filter(e.target.value)} class="level-option"></input>
                    <span class="checkmark"></span>
                  </label>
                );
              })}
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CourseSearch };
