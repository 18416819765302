import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';

const MainSearch = (props) => {
  const [q, setQ] = useState(props.search);
  const [limit, setLimit] = useState(props.search);

  const searchdata = () => {   
    history.push("/search");
  };

  const limits = [
    { label: "All", value: 'All' },
    { label: "Courses", value: 'Courses' },
    { label: "In-house", value: 'Workshop' },
    { label: "Hubs", value: 'Hubs' },
  ];

  return (
    <div>      
      <input
        name="search"
        type="text"
        placeholder="Search"
        value={q}
        onChange={e => setQ(e.target.value)}
        className="form-control"
      />

      {/*<a href={'/search?q='+q+'&limit='+limit} className='btn btn-warning'>Search</a>*/}                      
    </div>
  );
};

export {MainSearch};
