import React from 'react';
import { MainSearch } from '../components/MainSearch';
import { Search } from '../components/Search';
import { BrowserRouter as Router, Route } from 'react-router-dom'

function App() {
  return (
    <div className="App">
      <Router>
        <Route  path="/search" />
        <Route exact path="/" component={Search} />
      </Router>
    </div>
  );
}

export default App; 
