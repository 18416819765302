import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

const Calculator = (props) => {
  const { visible, setVisible, course, current_global_discount } = props;
  const [total, setTotal] = useState(course.total);
  const [attendees, setAttendees] = useState(1);
  const [courses, setCourses] = useState(1);

  const discountPercentage = (attendees > 1 || courses > 1) ? current_global_discount / 100 : 0;

  console.log({ current_global_discount, total, attendees, courses, discountPercentage });

  const cost = (total * courses * attendees) * (1 - discountPercentage);
  const discount = (total * courses * attendees) * discountPercentage;

  console.log({ cost });

  useEffect(() => {
    if (visible) {
      setTotal(course.total);
      setAttendees(1);
      setCourses(1);
    }
  }, [visible])

  return (
    <Modal
      show={visible}
      onHide={() => setVisible(false)}
      dialogClassName="calc-width"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Course Calculator
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex-table">
          <div className="flex-tr">
            <div className="flex-left-column">Number of attendees:</div>
            <div className="flex-right-column input-group spinner">
              <input id="num-attendees" type="number" className="form-condivol" value={attendees} min="1" max="10" onChange={(v) => setAttendees(v.target.value)} />
              <div className="input-group-btn-vertical">
                <button className="btn btn-default" type="button" onClick={() => setAttendees(attendees + 1)}><i className="fa fa-caret-up"></i></button>
                <button className="btn btn-default" type="button" onClick={() => setAttendees(attendees - 1)}><i className="fa fa-caret-down"></i></button>
              </div>
            </div>
          </div>
          <div className="flex-tr">
            <div className="flex-left-column">Number of courses:</div>
            <div className="flex-right-column input-group spinner">
              <input id="num-courses" type="number" className="form-condivol" value={courses} min="1" max="5" onChange={(v) => setCourses(v.target.value)} />
              <div className="input-group-btn-vertical">
                <button className="btn btn-default" type="button"><i className="fa fa-caret-up"></i></button>
                <button className="btn btn-default" type="button"><i className="fa fa-caret-down"></i></button>
              </div>
            </div>
          </div>
          <div className="flex-tr">
            <div className="flex-left-total">Total:</div>
            <div className="flex-right-total">
              <div id="total" className="total">
                <NumberFormat
                  value={cost}
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  prefix="$"
                  fixedDecimalScale
                />
              </div>
            </div>
          </div>
          <div className="flex-tr">
            <div className="flex-right-total">
              <div style={{ marginLeft: 5 }}> inc. GST</div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setVisible(false)}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export { Calculator };
