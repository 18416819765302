import React, { useState } from 'react';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { HelloWorld } from './HelloWorld';
import { Courses } from './Courses';
import { Calculator } from './Calculator';
import { Tickets } from './Tickets';

const logToConsole = (booking) => {
  console.log("*** testing Attendees: " + booking.attendees);
  console.log("*** Number of Attendees: " + booking.attendees);
  console.log("*** Number of Courses: " + booking.courses.length);
  console.log("*** Discount: " + booking.discount);
  console.log("*** Booking Total: " + booking.total);
  console.log("*** Discount: " + booking.discount);
  console.log("*** Sub Total: " + booking.subtotal);
  console.log("*** GST: " + booking.gst);
  console.log("*** Total: " + booking.total);
  console.log("*** Discount Type: " + booking.discount_type);
}

const resetDiscounts = (booking) => {
  booking.discount = 0;
  booking.discount_percentage = 0;
  booking.discount_description = 'Single';
  booking.discount_type = 'SINGLE';
  booking.promo_code = '';
};

const calculate = (booking) => {
  var ticket_price = _.sum(booking.courses, function (course) { return course.total });
  booking.subtotal = booking.attendees * ticket_price;
  if (booking.discountables.enabled) {
    if (booking.discountables.promo.code.length > 0) {
      booking.discount_percentage = booking.discountables.promo.percentage;
      booking.discount = (booking.subtotal * booking.discount_percentage / 100).toFixed(2);
      booking.discount_description = 'Promo Code';
      booking.discount_type = 'CODE';
      booking.promo_code = booking.discountables.promo.code;
    }
    else if (booking.courses.length >= booking.discountables.course.courses) {
      booking.discount_percentage = booking.discountables.course.percentage;
      booking.discount = (booking.subtotal * booking.discount_percentage / 100).toFixed(2);
      booking.discount_description = 'Multi Course';
      booking.discount_type = 'COURSE';
      booking.promo_code = "";
    }
    else if (booking.attendees >= booking.discountables.group.attendees) {
      booking.discount_percentage = booking.discountables.group.percentage;
      booking.discount = (booking.subtotal * booking.discount_percentage / 100).toFixed(2);
      booking.discount_description = 'Group 2+';
      booking.discount_type = 'GROUP';
      booking.promo_code = "";
    }
    else {
      resetDiscounts(booking);
    }
  }
  else {
    resetDiscounts(booking);
    if (booking.discountables.promo.code.length > 0) {
      booking.discount_percentage = booking.discountables.promo.percentage;
      booking.discount = (booking.subtotal * booking.discount_percentage / 100).toFixed(2);
      booking.discount_description = 'Promo Code';
      booking.discount_type = 'CODE';
      booking.promo_code = booking.discountables.promo.code;
    }
  }
  booking.total = (booking.subtotal - booking.discount).toFixed(2);
  booking.gst = (booking.total / 11).toFixed(2);
  logToConsole(booking);
};

const addAttendee = (formikProps) => {
  const { booking } = formikProps.values;
  booking.attendees++;
  booking.attendees_attributes.push({
    name: '',
    email: ''
  });
  formikProps.setValues({ ...formikProps.values });
  calculate(booking);
}

const removeAttendee = (formikProps, index) => {
  const { booking } = formikProps.values;
  booking.attendees--;
  booking.attendees_attributes.splice(index, 1);
  formikProps.setValues({ ...formikProps.values });
  calculate(booking);
}

const Booking = (props) => {
  const { booking, course, current_global_discount, bookings_url, related_course_path } = props;
  const [amount, setAmount] = useState(course.total);
  const [calculatorVisible, setCalculatorVisible] = useState(false);
  const [coursesVisible, setCoursesVisible] = useState(false);
  const [promoresult, setPromoresult] = useState('');

  console.log({ booking });

  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    console.log({ values, actions });
    try {
      const response = await axios.post(`${bookings_url}.json`, values, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log({ response });
      if (response.data && response.data.url) {
        window.location.href = response.data.url;
        actions.setSubmitting(false);
      }
    } catch (error) {
      actions.setSubmitting(false);
      console.log({ error });
      alert(error);
    }
  }

  const checkPromo = async (formikProps) => {
    const { booking } = formikProps.values;
    console.log({ formikProps });
    if (booking.promo_code.length > 0) {
      try {
        const { data } = await axios.get(`/codes/check?code=${booking.promo_code}`);
        if (data) {
          console.log({ data });
          booking.discountables.promo.percentage = data.discount;
          booking.discountables.promo.code = booking.promo_code;
          calculate(booking);
          formikProps.setValues({ ...formikProps.values });
          setPromoresult('Success!');
        }
        else {
          resetDiscounts(booking);
          formikProps.setValues({ ...formikProps.values });
          setPromoresult('Sorry, code not found!');
        }
      } catch (error) {
        resetDiscounts(booking);
        formikProps.setValues({ ...formikProps.values });
        setPromoresult('Sorry, code not found!');
      }
    }
    else {
      booking.discountables.promo.percentage = 0;
      booking.discountables.promo.code = '';
      resetDiscounts(booking);
      calculate(booking);
    }
  };

  const initialValues = {
    booking: {
      course_ids: [course.id],
      courses: [course],
      discountables: {
        enabled: course.discount,
        group: {
          message: `There is a <strong>${current_global_discount}% discount</strong> available for bookings of 2 or more people!`,
          attendees: 2,
          percentage: current_global_discount
        },
        course: {
          courses: 2,
          percentage: current_global_discount
        },
        promo: {
          code: '',
          percentage: 0
        }
      },
      attendees: 1,
      attendees_attributes: [{
        name: '',
        email: ''
      }],
      discount_percentage: 0,
    }
  };

  resetDiscounts(initialValues.booking);
  calculate(initialValues.booking);

  console.log({ initialValues });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={yup.object().shape({
        booking: yup.object().shape({
          attendees_attributes: yup.array().of(yup.object().shape({
            name: yup.string().required('can\'t be blank').test({ test: (value) => value && value.split(' ').length > 1 && value.split(' ')[1].length > 0, message: 'first and last name' }),
            email: yup.string().email('must be an email').required('can\'t be blank')
          }))
        })
      })}
    >
      {(formikProps) => (
        <div className="white-background round" style={{ padding: 20 }}>
          <Calculator
            course={course}
            visible={calculatorVisible}
            setVisible={setCalculatorVisible}
            current_global_discount={current_global_discount}
          />
          <Courses
            course={course}
            visible={coursesVisible}
            setVisible={setCoursesVisible}
            related_course_path={related_course_path}
            course_ids={formikProps.values.booking.course_ids}
            handleOk={(courses) => {
              const { booking } = formikProps.values;
              const temp = booking.courses.concat(courses);
              formikProps.values.booking.courses = temp;
              formikProps.values.booking.course_ids = temp.map(c => c.id);
              formikProps.setValues({ ...formikProps.values });
              console.log('WHOA', formikProps.values);
              calculate(formikProps.values.booking);
            }}
          />
          <Tickets booking={formikProps.values.booking} course={course} />
          {course && course.discount && formikProps.values.booking.courses.length === 1 && (
            <div className="row" style={{ marginTop: 20 }}>
              <div className="col-md-12">
                <div className="well" style={{ marginBottom: 20 }}>
                  <div className="color: #505050;"><strong>A combined {current_global_discount}% DISCOUNT applies if you book 2 or more courses together OR book 2 or more people into the same course.</strong></div>
                  <a className="href" name="step1" onClick={() => setCalculatorVisible(true)}>USE THE MULTI-COURSE DISCOUNT CALCULATOR</a>
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-md-12">
              <div style={{ padding: 0 }}>
                <a className="btn primary reversed" onClick={() => setCoursesVisible(true)}>
                  <i className="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;Add course
                </a>
                <hr />
              </div>
            </div>
          </div>
        <div className="row">
          <div className="col-xs-12">
            <h2 className="bookings">Confirm Attendees</h2>
              <Form>
                <div style={{ paddingTop:0, paddingBottom: 0 }}>
                  <div id="attendee-table-wrapper">
                    <table id="checkout-table">
                      <thead>
                        <tr>
                          <th width="45%">Attendee/s name</th>
                          <th width="45%">Email</th>
                          <th width="10%" style={{ paddingLeft: 25 }}></th>
                        </tr>
                      </thead>
                      <tbody id="checkout-table-body">
                        {formikProps.values.booking.attendees_attributes.map((attendee, index) => (
                          <tr className="fields attendee-details" key={index}>
                            <td style={{ paddingRight: 10 }}>
                              <div className="control-group string required booking_attendees_name">
                                <Field name={`booking[attendees_attributes][${index}][name]`}>
                                  {({
                                    field, // { name, value, onChange, onBlur }
                                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                    meta,
                                  }) => (
                                    <div className="controls">
                                      <input className="string required form-control" type="text" placeholder="First and last name" {...field} />
                                      {meta.touched && meta.error && (
                                        <span className="help-inline">{meta.error}</span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </div>
                            </td>
                            <td style={{ paddingRight: 10 }}>
                              <div className="control-group string required booking_attendees_email">
                                <Field name={`booking[attendees_attributes][${index}][email]`}>
                                  {({
                                    field, // { name, value, onChange, onBlur }
                                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                    meta,
                                  }) => (
                                      <div className="controls">
                                        <input className="string required form-control" type="email" placeholder="Email address" {...field} />
                                        {meta.touched && meta.error && (
                                          <span className="help-inline">{meta.error}</span>
                                        )}
                                      </div>
                                    )}
                                </Field>
                              </div>
                            </td>
                            <td width="180px" className="total price attendee-total-cost" style={{ paddingLeft: 10 }}>
                              {formikProps.values.booking.attendees > 1 && (
                                <span className="remove">
                                  <a className="href" onClick={() => removeAttendee(formikProps, index)}>
                                    <i className="icon-remove" /> <span className="hidden-xs hidden-sm">Remove</span>
                                  </a>
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <a
                      className="btn primary reversed"
                      style={{ marginTop: -5 }}
                      onClick={(e) => addAttendee(formikProps)}
                    >
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;Add attendee
                    </a>
                    <div className="row">
                      <div className="col-xs-12">
                        <hr />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12" style={{ paddingTop: 0 }}>
                        <h4 style={{ fontSize: 14, paddingBottom: 10, marginBottom: 10, borderBottom: '1px dashed #ccc' }}>Enter promo code</h4>
                        <Field className="string optional form-control" type="text" name="booking[promo_code]" style={{ maxWidth: 428 }} />
                        {/* <%= f.input :promo_code, :label => false, :input_html => {:class => "form-control", :style => "min-width: 250px; max-width:428px;"} %> */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-8 col-sm-5">
                        <a id="promo-check" className="btn primary reversed" style={{ float: 'left' }} onClick={() => checkPromo(formikProps)}>
                          <span>
                            <i className="fa fa-check-square-o" aria-hidden="true"></i>
                            <span>{formikProps.values.booking.promoDiscount > 0 ? "" : "Apply"}</span>
                          </span>
                        </a>
                        <div id="promo-result" style={{ float: 'left', paddingTop: 18, paddingLeft: 15 }}>
                          {promoresult}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12">
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-2 col-sm-6"></div>
                    <div className="col-xs-10 col-sm-6">
                      <table id="checkout-totals">
                        <tbody>
                          <tr>
                            <td>
                              {formikProps.values.booking.subtotal && (
                                <div>
                                  {formikProps.values.booking.attendees === 1 ? '1 person' : `${formikProps.values.booking.attendees} people`},&nbsp;
                                  {formikProps.values.booking.courses.length === 1 ? '1 course' : `${formikProps.values.booking.courses.length} courses`}
                                </div>
                              )}
                            </td>
                            <td className="total price">
                              <span>
                                <NumberFormat
                                  value={formikProps.values.booking.subtotal}
                                  displayType="text"
                                  thousandSeparator
                                  decimalScale={2}
                                  prefix="$"
                                  fixedDecimalScale
                                />
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td id="discount" style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row' }}>
                              <div>{formikProps.values.booking.discount_description}</div>
                              <div style={{ marginLeft: 5 }}> Discount&nbsp;
                                <span>
                                  {formikProps.values.booking.discount_percentage}%
                                </span>
                              </div>
                            </td>
                            <td className="total price">
                              <span>
                                <NumberFormat
                                  value={formikProps.values.booking.discount}
                                  displayType="text"
                                  thousandSeparator
                                  decimalScale={2}
                                  prefix="$"
                                  fixedDecimalScale
                                />
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="light">GST</td>
                            <td className="total price light">
                              <span>
                                <NumberFormat
                                  value={formikProps.values.booking.gst}
                                  displayType="text"
                                  thousandSeparator
                                  decimalScale={2}
                                  prefix="$"
                                  fixedDecimalScale
                                />
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="total price"><strong>TOTAL</strong></td>
                            <td className="total price">
                              <span>
                                <NumberFormat
                                  value={formikProps.values.booking.total}
                                  displayType="text"
                                  thousandSeparator
                                  decimalScale={2}
                                  prefix="$"
                                  fixedDecimalScale
                                />
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <div id="checkout-button">
                        <div className='checkout-column'>
                          <div className='checkout-line'>
                            <button className="button btn primary" type="submit" disabled={formikProps.isSubmitting}>
                              {formikProps.isSubmitting && <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>}
                              <span>Checkout</span>
                            </button>
                          </div>
                          <div className='checkout-column checkout-text checkout-text_black'>
                            <a href="https://www.net101.com.au/page/net-101-course-booking-terms-conditions-1" target="_blank">View booking terms</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

export { Booking };
