import PropTypes from 'prop-types';
import axios from 'axios';
import React, { useState } from 'react';
import phone_icon from '../../assets/images/tel.png';


const CourseSideComponent = (props) => {
  const {places_left, duration, start_date, starts_at, is_closed, location_name, address, price, discount, enroll_path, faq_path, trainer_pic, trainer_email, trainer_name, trainer_phone, user_path, formet, contact_path, course_sessions} = props;

  const handleEnroll = async (path) => {
    var value = globalThis.$('.custom-select select').val();
    if (!value) {
      value = 1;
    }
    window.location.href = `${path}&quantity=${value}`
  }

  const sessionItems = course_sessions.map((session) =>
      <div style={{marginTop: '12px'}}>
        <div className="field" style={{fontSize: '16px', fontWeight: '500'}}>Session {session.index}</div>
        <div className="field">
          {session.date_time[0]}
        </div>
        <div className="field">
          {session.date_time[1]}
        </div>
      </div>
  );

  const quantityOptions = Array.from({length: places_left}, (_, i) => i + 1).map((i) =>
    <option>{i}</option>
  );

  return (
    <div className="side-content" id="sidebar">
      <ul className="list-unstyled">
        {formet !== "Livestream" && !is_closed &&
          <li>
            <div className="alignleft">
              <p>SPOTS LEFT</p>
            </div>
            <div className="alignright">
              <span className="count">{places_left}</span>
            </div>
          </li>
        }
        <li>
          <div className="alignleft">
            <p>FORMAT</p>
          </div>
          <div className="alignright">
            <p>{formet}</p>
          </div>
        </li>
        <li>
          <div className="alignleft">
            <p>DURATION</p>
          </div>
          <div className="alignright">
            <p>{duration}</p>
          </div>
        </li>
        <li>
          <div className="alignleft">
            <p>STARTS</p>
          </div>
          {is_closed &&
            <div className="alignright">
              EXPIRED
            </div>
          }
          {formet === "Self-paced" &&
            <div className="alignright">
              Anytime
            </div>
          }
          {(formet !== "Self-paced" && !is_closed && !!starts_at) &&
            <div className="alignright">
              <div>
                {!!course_sessions.length &&
                  <div className="field" style={{fontSize: '16px', fontWeight: '500'}}>Session 1</div>
                }
                <div className="field">
                  {starts_at[0]}
                </div>
                <div className="field">
                  {starts_at[1]}
                </div>
              </div>
              {/*If Session 2 exists*/}
              {sessionItems}
            </div>
          }
        </li>
        {/*<li>
          <div>&nbsp;</div>
          <div className="alignright">
            <p className="text-right">{starts_at}</p>
          </div>
        </li>*/}
        {(formet !== "Self-paced" && places_left > 0) &&
          <li>
            <div className="alignleft">
              <p>QUANTITY</p>
            </div>
            <div className="alignright">
              <div className="custom-select">
                {places_left >= 5 &&
                  <select className="form-control" style={{width: 60}}>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </select>
                }
                {places_left < 5 &&
                  <select className="form-control" style={{width: 60}}>
                    {quantityOptions}
                  </select>
                }
              </div>
            </div>
          </li>
        }
        {formet !== "Self-paced" &&
          <li className="no-middle">
            <div className="alignleft">
              <p>{formet === "Livestream" ? "PLATFORM" : "VENUE"}</p>
            </div>
            <div className="alignright">
              {formet === "Livestream" &&
                <address>
                  <span>{location_name} {address}</span>
                </address>
              }
              {formet !== "Livestream" &&
                <address>
                  <i className="fa fa-map-marker" />
                  <span style={{ maxWidth: "185px" }}>{location_name}<br/>{address}</span>
                </address>
              }
            </div>
          </li>
        }
        <li className="no-border" style={{ alignItems: 'flex-end' }}>
          <div className="alignleft">
            <p style={{ lineHeight: '16px' }}>PRICE</p>
          </div>
          <div className="alignright">
            <p className="price" style={{ lineHeight: '24px' }}>{price}</p>
          </div>
        </li>
        {formet !== "Self-paced" &&
          <li className="no-border">
            <span className="small-txt"><p>{discount}. Discount applied at check-out.</p></span>
          </li>
        }
        {!is_closed &&
          <li className="no-border btn-holder">
            {formet === "Self-paced" &&
              <a href={enroll_path} target="_blank" className="btn-warning btn-block text-center">Enrol</a>
            }
            {(formet !== "Self-paced" && places_left > 0) &&
              <a onClick={(e) => handleEnroll(enroll_path)} className="btn-warning btn-block text-center">Enrol</a>
            }
            <div className="text-center" style={{width: '100%'}}>
              <a href={faq_path} className="link">VIEW FAQ</a>
            </div>
          </li>
        }
      </ul>
      <div className="profile-section">
        <div className="row flex">
          <div className="col-xs-4">
            <div className="img-holder">
              <a href={user_path}><img src={trainer_pic}/></a>
            </div>
          </div>
          <div className="col-xs-8">
            <p className="title-h">GOT ANY QUESTIONS?</p>
            <p><a href={contact_path}>Contact {trainer_name}</a></p>
            <a href={ `tel:${trainer_phone}` } className="phone-number"><img src={ phone_icon }/> <span>{trainer_phone}</span></a>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CourseSideComponent };
