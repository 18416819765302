import React, { useState } from 'react';
import NumberFormat from 'react-number-format';

const Tickets = (props) => {
  const { booking, course } = props;

  console.log('Tickets', { booking });

  return (
    <div className="tickets row hidden-xs hidden-sm ">
      {booking.courses.map(course => {
        return (
          <div className="ticket col-xs-12" key={course.id}>
            <div className="checkout checkout-tag-2">
              <div className="checkout-punch"></div>
              <img alt={course.title} className="poster round" src={course.thumb_url} title={course.title} />
              <div className="checkout-detail">
                <h2>{course.title}</h2>
                <p className="presenter">
                  Presented by {course.trainer_name}
                </p>
                <div>
                  <span className="session-date"><i className="icon-calendar icon-wide"></i> {course.starts_at}</span>
                </div>
                <div>
                  {
                    course.sessions.map(session => {
                      return (
                        <span className="session-date" key={session.starts_at}>
                          <i className="icon-calendar icon-wide"></i> {session.starts_at}
                        </span>
                      );
                    })
                  }
                </div>
              </div>
              <div className="checkout-totals">
                <style>{'.ticket-table tr td:last-child { padding-left: 0 !important; }'}</style>
                <table className="ticket-table">
                  <tbody>
                    <tr>
                      <td>
                        {booking.attendees === 1 ? '1 Person' : `${booking.attendees} People`}
                      </td>
                      <td>
                        <NumberFormat
                          value={course.total * booking.attendees}
                          displayType="text"
                          thousandSeparator
                          decimalScale={2}
                          prefix="$"
                          fixedDecimalScale
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>{booking.discount_percentage}% Discount</td>
                      <td>
                        <NumberFormat
                          value={booking.discount / booking.courses.length}
                          displayType="text"
                          thousandSeparator
                          decimalScale={2}
                          prefix="$"
                          fixedDecimalScale
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr id="checkout-totals-total">
                      <td>Subtotal</td>
                      <td>
                        <NumberFormat
                          value={course.total * booking.attendees - (booking.discount / booking.courses.length)}
                          displayType="text"
                          thousandSeparator
                          decimalScale={2}
                          prefix="$"
                          fixedDecimalScale
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )
      })}
    </div>

  )
}
export { Tickets };
