import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';

const Courses = (props) => {
  const { visible, setVisible, course, related_course_path, handleOk, course_ids } = props;

  const [courses, setCourses] = useState([]);

  console.log({ related_course_path });

  useEffect(() => {
    if (visible) {
      (async () => {
        const { data } = await axios.get(`${related_course_path}.json`);
        console.log({ data });
        data.map(d => d.selected = false);
        setCourses(data.filter(c => !course_ids.includes(c.id))); // filter out courses already in cart
      })();
    } else {
      setCourses([]);
    }
  }, [visible, course])

  const selectedCourses = courses.filter(c => c.selected);


  const buttonHelpText = () => {
    if (selectedCourses.length === 1) {
      return "1 Additional Course Selected"
    }
    else if (selectedCourses.length > 1) {
      return selectedCourses.length + " Additional Courses Selected"
    }
    else {
      return "No Additional Courses Selected";
    }
  }

  const toggleSelected = (courseId) => {
    let items = [...courses];
    const idx = items.findIndex(c => c.id === courseId);
    items[idx].selected = !items[idx].selected;
    setCourses(items);
  }

  const ok = () => {
    handleOk(selectedCourses);
    setVisible(false);
  }

  const header = courses.length > 0 ? "Add an additional course to your booking" : " from " + courses.length + " other courses in " + course.suburb;

  return (
    <Modal
      show={visible}
      onHide={() => setVisible(false)}
      dialogClassName="courses-max-width"
    >
      <style>{'.courses-max-width { max-width: 1024px }'}</style>
      <Modal.Header>
        <Modal.Title>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <h3 className='modal-title' style={{ marginTop: 5, color: '#333333', fontWeight: 300, paddingLeft: 15 }}>{header}</h3>
            <div>
              <span style={{ color: '#be1e2d', marginRight: 10 }}><i>{buttonHelpText()}</i></span>
              <button className='btn btn-default btn-primary' style={{ backgroundColor: '#be1e2d' }} onClick={ok}>
                Continue
              </button>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-xs-12">
            <div className="row">
              <div id="tiles" className="tiles">
                {courses.map(course => {
                  return (
                    <div className="col-xs-12 col-sm-4 col-md-3" key={course.id}>
                      <div className={`tile-wrapper ${course.selected ? 'selected' : ''}`} onClick={() => toggleSelected(course.id)}>
                        <div className="course tile">
                          <div className="tile-top">
                            <span className="gradient"></span>
                            <img src={course.thumb_url} className="img-responsive-grow" style={{ maxHeight: 200 }} />
                            <div className="tile-title">
                              {course.title.split("-")[0]}
                            </div>
                            <span className="quick-info">
                              <span className="top-background"></span>
                              <span className="top">
                                <p>
                                  <span className="big-text">{course.starts_at_day}</span><br />
                                  {course.starts_at_month}
                                </p>
                              </span>
                              {course.selected && (
                                <span className="bottom">
                                  <i className="fa fa-check" style={{ textDecoration: 'none', fontSize: 28, color: '#FFFFFF' }}></i>
                                </span>
                              )}
                            </span>
                          </div>
                          <div className="details" style={{ paddingTop: 15 }}>
                            <div className="detail">
                              <div>
                                <i className="fa fa-calendar fa-fw"></i>
                                {course.starts_at_modal}
                              </div>
                              <i className="fa fa-map-marker fa-fw"></i>
                              {course.suburb}
                            </div>
                            {course.selected ? (
                              <span className="add-course">
                                <a href="#" style={{ textDecoration: 'none', color: 'rgba(190, 30, 45, 0.5)', fontSize: 10, marginLeft: 3 }}>
                                  CLICK TO REMOVE
                                </a>
                              </span>
                            ) : (
                              <span className="add-course">
                                <a href="#" style={{ textDecoration: 'none', color: 'rgba(190, 30, 45, 0.5)', fontSize: 10, marginLeft: 3 }}>
                                  <i className="fa fa-plus" style={{ fontSize: 14 }}></i> ADD THIS COURSE
                                </a>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setVisible(false)}>
          Cancel
          </Button>
        <Button variant="primary" onClick={ok}>
          Continue
          </Button>
      </Modal.Footer>
    </Modal>
  );
}

export { Courses };
